import LoggerManager from "../src-log/LoggerManager";
  
  //WARN: do not rename this variable as it is used by the build script!
  var messageStrings = [];
  
  //WARN: do not touch the following comment as it is replaced with the messageString initialization during build!
  //ARRAY_IMPLEMENTATION_PLACEHOLDER
  
  
  
  LoggerManager.resolve = function(id){
    return id + "] " + messageStrings[id];
  };
  
  /**
   * This is a fake constructor that should not be used.
   * @constructor
   * 
   * @exports LogMessages
   *
   * @class Used internally by the library to produce "real" log messages
   * in the log generated by the internal classes. If this class is not included in the 
   * generated library, only codes will be printed.
   * <BR>Note that if the library is used in the AMD version then it may be necessary,
   * depending on the AMD implementation in use, to require this class to make the 
   * "real" log messages appear in the log. 
   * <BR>It is suggested not to import this class unless a client log is needed 
   * for debugging purposes.
   * <BR>It is possible to include this class in a JavaScript application to 
   * programmatically obtain a log message associated to a given log code
   * exploiting the {@link LogMessages.getMessage} method.
   */
  var LogMessages = function() {
  };
  
  /**
   * Given a log code as generated by a library running without the LogMessages module,
   * this method can be used to extract the associated log message.
   *  
   * @param {Number} id The id of the essage to be extracted.
   */
  LogMessages.getMessage = function(id) {
    return LoggerManager.resolve(id);
  };
  
  LoggerManager["resolve"] = LoggerManager.resolve;
  LogMessages["getMessage"] = LogMessages.getMessage;
  
  export default LogMessages; 

